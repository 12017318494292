@tailwind base;
@tailwind components;
@tailwind utilities;

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: Eurostile;
  src: url(../../public/fonts/EurostileExtended.otf);
}

@font-face {
  font-family: HyperScript;
  src: url(../../public/fonts/HyperScript.otf);
}

@font-face {
  font-family: Kilo;
  src: url(../../public/fonts/Kilo.otf);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Eurostile, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.background {
  position: absolute;
  width: 100%;
  height: 400vh;
  background: fixed #944cff;
  -webkit-mask-attachment: fixed;
  -webkit-mask: url(/images/bg_smoke.svg) top / cover  no-repeat;
  mask: url(/images/bg_smoke.svg) top / cover  no-repeat;
  max-height: 100%;
}

header {
  width: 100%;
  height: 160px; 
  font-family: HyperScript; 
}

.page {
  height: calc(100vh - 160px);
}

.kilo {
  font-family: Kilo; 
}

.hyper {
  font-family: Hyperscript; 
}

